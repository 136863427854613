/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelector } from "react-redux";
import useLiquidations from "../../../hooks/useLiquidations";
import MainLayout from "../../../layouts/Desktop/MainLayout";
import { toast } from "react-toastify";
import "./Liquidation.css";
import { useState } from "react";
import { PiSpinnerBold } from "react-icons/pi";
import EmptyTable from "../../../assets/emptytable.png";
import "./Liquidation.css";
import ReactPaginate from "react-paginate";
import LoadingTable from "../../../components/Table/LoadingTable";

declare const window: any;
function Liquidation() {
  const { _liquidate } = useLiquidations();
  const { liquidations, liquidLoading } = useSelector(
    (state: any) => state.baki
  );

  const handleLiquidate = async (_address: string) => {
    const result = await _liquidate(_address);
    if (result) {
      toast.success("Liquidation Successful !!");
      window.location.reload();
      return;
    } else {
      toast.error("Liquidation Failed !!");
      return;
    }
  };
  


  // PAGINATION VARIABLES
  // const liquidations = fakeLQData;
  const liquidationsLength = liquidations.length;
  const itemsPerPage = 9;
  const pageCount = Math.ceil(liquidationsLength / itemsPerPage);

  var [itemOffset, setItemOffset] = useState(0);
  const endOffset = itemOffset + itemsPerPage;
  let isPreviousDisabled;
  let isNextDisabled;
  const handlePageClick = (event: any) => {
    isPreviousDisabled = event.selected < 1;
    isNextDisabled = event.selected === pageCount - 1;
    var newOffset = (event.selected * itemsPerPage) % liquidationsLength;
    setItemOffset(newOffset);
    return;
  };

  return (
    <MainLayout>


      <div className="h-full relative pt-2 transition-all duration-700">
        <div className="w-full max-w-full p-4 h-full">
          <div className="relative w-full max-w-5xl mx-auto overflow-auto mb-11 flex justify-center items-center flex-col">
            <div className="w-full py-3 rounded-lg border-thin border-black">
              <p className="text-sm font-semibold leading-tight ps-3 pb-3">
                All Liquidations
              </p>

              {liquidLoading ? (
                <LoadingTable />
              ) : (
                <>
                  {liquidations?.length && (
                    <table className="min-w-full leading-normal table-auto ">
                      <thead className="">
                        <tr className="bg-[#F9F4ED] w-full text-left text-[x-small] text-gray-800 uppercase">
                          <th className="py-1 ps-3 font-semibold">OWNER</th>
                          {/* <th className="py-1 ps-3 font-semibold w-auto">fee</th> */}
                          <th className="py-1 ps-3 font-semibold">
                            POTENTIAL REWARDS
                          </th>
                          <th className="py-1 ps-3 font-semibold">
                            REQUIRED ZUSD
                          </th>
                          <th className="py-1 ps-3 font-semibold"> ACTION </th>
                        </tr>
                      </thead>

                      {liquidations
                        .map((liquidation: any, index: any) => (
                          <tbody key={index} className="text-[small]">
                            <tr className="hover:bg-gray-100 duration-500 ease-out text-xs">
                              <td className="px-3 pt-1 pb-2 border-b border-gray-200 bg-transparent">
                                <p className="">
                                  {liquidation.address?.slice(0, 5)}...{" "}
                                  {liquidation.address?.slice(35, 42)}
                                </p>
                              </td>
                              <td className="px-3 pt-1 pb-2 border-b border-gray-200 bg-transparent">
                                <p className="text-gray-900 whitespace-no-wrap font-normal">
                                  {`${liquidation.reward?.toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 2,
                                    }
                                  )}  USDC`}
                                </p>
                              </td>
                              <td className="px-3 pt-1 pb-2 border-b border-gray-200 bg-transparent">
                                <p>
                                  {`${liquidation.requiredZUSD?.toLocaleString(
                                    undefined,
                                    {
                                      maximumFractionDigits: 2,
                                    }
                                  )}  zUSD`}
                                </p>
                              </td>
                              <td className="px-3 pt-1 pb-2 border-b border-gray-200 bg-transparent">
                                <div className="inline-flex gap-2 h-full place-items-center">
                                  <LiquidateBTN
                                    liquidation={liquidation}
                                    handleLiquidate={handleLiquidate}
                                  />
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        ))}

                      {/* {!liquidations?.length && !liquidLoading && Liquidations.map((liquidation, index) => ( */}
                    </table>
                  )}
                  {!liquidations?.length && (
                    <>
                      <div className="w-full h-full pt-10 space-y-4">
                        <img
                          src={EmptyTable}
                          alt=""
                          className="h-24 w-auto mx-auto"
                        />
                        <p className="text-xs text-[#454647] text-center">
                          No liquidations were found!
                        </p>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="h-auto px-4 mb-3 w-5/6 fixed bottom-0 mx-auto">
          <ReactPaginate
            breakLabel="..."
            previousLabel="⇽ Prev"
            nextLabel="Next ⇾"
            pageCount={pageCount}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            pageRangeDisplayed={5}
            nextLinkClassName={`next`}
            previousLinkClassName={`prev`}
            disabledClassName="disable-nav"
            activeClassName="active-nav"
            renderOnZeroPageCount={null}
          />
        </div>
      </div>
    </MainLayout>
  );
}

export const LiquidateBTN = ({ liquidation, handleLiquidate }: any) => {
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <button
      disabled={loading}
      className={`bt-transparent m-0 p-0.5 min-w-max px-3 my-auto text-[x-small] rounded-sm border-thin border-black hover:bg-slate-900 hover:text-white duration-500 disabled:bg-opacity-80`}
      onClick={async () => {
        setLoading(true);
        await handleLiquidate(liquidation?.address);
        setLoading(false);
      }}
    >
      {loading ? (
        <PiSpinnerBold className="h-4 w-4 animate-spin" />
      ) : (
        "Liquidate"
      )}
    </button>
  );
};

export default Liquidation;
