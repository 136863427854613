/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
// import "./Borrow.css";
import ZUSD from "../../assets/ZUSD.png";
// import CUSD from "../../assets/cUSD.png";
// import AVAX from "../../assets/avax.png";
import USDC from "../../assets/usdc.png";
import { PiSpinnerBold } from "react-icons/pi";

import axios from "axios";
import { useEffect, useState } from "react";
import useDeposit from "../../hooks/useDeposit";
import useToken from "../../hooks/useToken";
import { useSelector } from "react-redux";
// import redstone from "redstone-api";
import { config } from "../../config";
import { toast } from "react-toastify";
import { TiArrowDownOutline } from "react-icons/ti";

import millify from "millify";
import { removeLastNonZeroDigit } from "../../helper";

axios.defaults.baseURL = `https://api.coinlayer.com/api/live?access_key=${config.coinlayerAPIKEY}`;
declare const window: any;

interface RepayProps {
  rewardBal: any;
  handleClaimReward: any;
  claimLoading: Boolean;
}

function Borrow({ rewardBal, handleClaimReward, claimLoading }: RepayProps) {
  const { deposit } = useDeposit();
  const { zUSDBal, userDebt, collateral, activeCol, userColBalance } =
    useSelector((state: any) => state.baki);
  const [perVal, setPerVal] = useState<number>(0);
  const [onFocus, setOnFocus] = useState<boolean>(false);
  const [depositAmount, setDepositAmount] = useState<any>(0);
  const [mintAmount, setMintAmount] = useState<any>(0);
  const [_approve, setApprove] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const { approve, allowance } = useToken("USDC", false);

  useEffect(() => {
    if (depositAmount > allowance) {
      setApprove(false);
    } else {
      setApprove(true);
    }
  }, [depositAmount]);

  const handleApprove = async () => {
    if (depositAmount <= 0) return;
    setLoading(true);
    const result = await approve(depositAmount);
    if (result) setApprove(true);
    setLoading(false);
  };

  const mint = async () => {
    if (mintAmount < 0) return;
    if (userColBalance >= 0 || mintAmount >= 0) {
      setLoading(true);

      let result = await deposit(depositAmount, mintAmount);
      if (result === true) {
        setLoading(false);
        toast.success("Transaction Successful !!");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setLoading(false);
      }
    }
  };

  const calculateValue = (percentage: number) => {
    if (depositAmount || userColBalance) {
      setPerVal(percentage);
      let debt = +userDebt;
      let colRatio = 1.5;
      let val2 = (+userColBalance + +depositAmount) / colRatio;
      val2 = val2 * percentage;
      let val3 = val2 - debt;
      let acVal = Math.max(0, val3);
      setMintAmount(acVal.toFixed(6));
    }
  };

  return (
    <div className="gap-8 p-3 space-y-3 bg-[#FFFAF3] border-thin border-gray-300 rounded-xl">
      {/* added */}
      <div className="claim-rewards w-full rounded-xl border-thin border-gray-400 px-3 md:px-8 py-3 flex justify-between">
        <div className="my-auto">
          <p className="text-xs">UNCLAIMED REWARDS</p>
          <span className="font-semibold text-xl">
            {rewardBal?.toLocaleString(undefined, {
              maximumFractionDigits: 5,
            })}
            <span className="">zUSD</span>
          </span>
        </div>
        <button
          className="claim-btn my-auto text-white bg-[#241F17] md:rounded-3xl rounded-md px-3 py-2 align-middle disabled:bg-opacity-75"
          onClick={handleClaimReward}
          disabled={rewardBal < 0}
        >
          {claimLoading ? (
            <PiSpinnerBold className="h-4 w-4 animate-spin" />
          ) : (
            <>
              <p className="block md:hidden text-xs p-0 my-auto">Claim Now</p>
              <p className="hidden md:block text-xs p-0 my-auto">Claim</p>
            </>
          )}
        </button>
      </div>

      <p className="text-center text-xs py-2">
        Select the asset you would like to deposit.
      </p>

      <div>
        <div className="flex justify-between text-[x-small] items-center w-full">
          <p className="">Deposit Collateral</p>
          <p className="" onClick={() => setDepositAmount(collateral)}>
            USDC balance:{" "}
            {collateral?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </p>
        </div>

        <div className="inline-flex bg-white mint-field rounded-md p-2 mb-2 text-xs w-full border-thin border-gray-400">
          <div className="flex items-center w-1/2 border-r-thin border-gray-400">
            <img src={USDC} alt="USDC" className="h-5" />
            <span className="ml-1 text-font-grey">USDC </span>
          </div>
          <input
            type="number"
            placeholder="Enter Amount"
            value={depositAmount ? depositAmount : ""}
            onChange={(e) => setDepositAmount(e.target.value)}
            className="w-1/2 px-2 text-right focus:outline-none "
          />
        </div>
      </div>

      <div className="mt-12">
        <div className="relative w-full my-2 border-thin  border-gray-300">
          <div className="absolute inset-x-[48%] inset-y-[49%] my-auto rounded-full bg-[#FEE7C0] p-1 w-5 h-5">
            <TiArrowDownOutline className="h-full w-auto text-[#D6A45B]" />
          </div>
        </div>
      </div>

      <div className="">
        <div className="flex justify-between items-center text-[x-small] w-full">
          <p>MINT zUSD</p>
          <p onClick={() => setMintAmount(zUSDBal)}>
            zUSD balance:{" "}
            {zUSDBal?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            })}
          </p>
        </div>

        <div className="inline-flex bg-white mint-field rounded-md p-2 text-xs w-full border-thin border-gray-400">
          <div className="flex items-center w-1/2 border-r-thin border-gray-400">
            <img src={ZUSD} alt="zUSD" className="h-5" />
            <span className="ml-1 text-font-grey text-[x-small]">zUSD </span>
          </div>
          <input
            value={mintAmount ? mintAmount : ""}
            type="number"
            placeholder="Enter Amount"
            onChange={(e) => setMintAmount(e.target.value)}
            className="w-1/2 px-2 text-right focus:outline-none"
          />
        </div>
      </div>
      <div className="w-full grid grid-cols-6 gap-2">
        <button
          className="col-span-1 p-1 text-xs rounded-md border-thin border-gray-400 hover:bg-black hover:text-white duration-300"
          onClick={() => calculateValue(10 / 100)}
        >
          10%
        </button>
        <button
          className="col-span-1 p-1 text-xs rounded-md border-thin border-gray-400 hover:bg-black hover:text-white duration-300"
          onClick={() => calculateValue(25 / 100)}
        >
          25%
        </button>
        <button
          className="col-span-1 p-1 text-xs rounded-md border-thin border-gray-400 hover:bg-black hover:text-white duration-300"
          onClick={() => calculateValue(50 / 100)}
        >
          50%
        </button>
        <button
          className="col-span-1 p-1 text-xs rounded-md border-thin border-gray-400 hover:bg-black hover:text-white duration-300"
          onClick={() => {
            calculateValue(75 / 100);
            setOnFocus(false);
          }}
        >
          75%
        </button>
        <div
          className="col-span-2 p-1 text-xs rounded-md border-thin border-gray-400 max-w-full"
          onClick={() => {
            calculateValue(0);
            setOnFocus(true);
          }}
        >
          <input
            type="number"
            placeholder="Custom"
            className=" w-full px-2 border-0 text-right focus:outline-none bg-transparent"
            style={{
              borderColor: onFocus ? "#682609" : "#dbdedf",
            }}
            onChange={(e) => calculateValue(Number(e.target.value) / 100)}
          />
        </div>
      </div>

      <button
        onClick={() => (!_approve ? handleApprove() : mint())}
        className={`w-full ${
          !_approve ? "bg-[#FB7F37]" : "bg-black"
        }  text-white rounded p-2 mt-4 text-xs flex justify-center items-center disabled:bg-opacity-75 `}
        disabled={loading}
      >
        {!_approve ? (
          <>
            {loading ? (
              <PiSpinnerBold className="h-4 w-4 animate-spin" />
            ) : (
              "Approve"
            )}
          </>
        ) : (
          <>
            {" "}
            {loading ? (
              <PiSpinnerBold className="h-4 w-4 animate-spin" />
            ) : (
              "Deposit and Mint"
            )}
          </>
        )}
      </button>
    </div>
  );
}

export default Borrow;
